import axios from 'axios';

export default {
  namespaced: true,
  state: {
    summarizedOrrOptions: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  mutations: {
    updatesummarizedOrrOptions(state, newData) {
      state.summarizedOrrOptions = newData;
    },
  },
  actions: {
    getSummarizedOrrOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/orr-sections-scoring/',
          method: 'get',
        }).then((response) => {
          context.commit('updatesummarizedOrrOptions', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updatesummarizedOrrOptions', []);
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    summarizedOrrOptions(state) {
      return state.summarizedOrrOptions;
    },
  },
};
