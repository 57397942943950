import axios from 'axios';

export default {
  namespaced: true,
  state: {
    leasingRequest: {
      customer: '',
      request_date: null,
      total_amount: 0,
      total_down_payment: 0,
      serial_number: '',
      type_of_facility: '',
      type_of_installment: '',
      type_of_request: '',
      leasing_assets: [],
      request_status: '',
    },
    summarizedLeasingRequests: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    tradeNames: [],
    requestHistory: [],
    requiredDocumentsOptions: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    requiredDocuments: [],
    document: {},
    customerRequests: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  mutations: {
    updateLeasingRequest(state, newLeasingRequest) {
      state.leasingRequest = newLeasingRequest;
    },
    resetLeasingRequest(state) {
      state.leasingRequest = {
        customer: '',
        request_date: null,
        total_amount: 0,
        total_down_payment: 0,
        serial_number: '',
        type_of_facility: '',
        type_of_installment: '',
        type_of_request: '',
        leasing_assets: [],
        request_status: '',
      };
    },
    resetsummarizedLeasingRequests(state) {
      state.summarizedLeasingRequests = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    },
    resetRequestHistory(state) {
      state.requestHistory = [];
    },
    updateSummarizedLeasingRequests(state, newLeasingRequests) {
      state.summarizedLeasingRequests = newLeasingRequests;
    },
    updateTradeNames(state, newNames) {
      state.tradeNames = newNames;
    },
    updateRequestHistory(state, newRequestHistory) {
      state.requestHistory = newRequestHistory;
    },
    updateRequiredDocumentsOptions(state, newRequiredOptions) {
      state.requiredDocumentsOptions = newRequiredOptions;
    },
    updateRequiredDocuments(state, newRequestDocuments) {
      state.requiredDocuments = newRequestDocuments;
    },
    updateDocument(state, newData) {
      state.document = newData;
    },
    updateCustomerRequests(state, newRequests) {
      state.customerRequests = newRequests;
    },
    resetCustomerRequests(state) {
      state.customerRequests = {
        count: 0,
        next: null,
        previous: null,
        results: [],
      };
    },
  },
  actions: {
    saveLeasingRequest(context, requestInfo) {
      const requestId = Number(requestInfo.id);
      console.log('request id', requestId);
      console.log('store here', requestId === '0');
      const reqMethod = requestId > 0 ? 'put' : 'post';
      const reqUrl = requestId > 0
        ? `/api/leasing/edit/${requestId}/`
        : '/api/leasing/create/';
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: reqMethod,
          data: requestInfo,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLeasingRequest(context, requestId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/requests/${requestId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateLeasingRequest', response.data);
          console.log('lease', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetLeasingRequest');
          reject(error.response.data);
        });
      });
    },
    getSummarizedLeasingRequests(context, lazyParams) {
      console.log('page number:', lazyParams);
      console.log('Here is the investigation:');
      console.log('filter:', lazyParams.filters);
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/requests/?page=${lazyParams.page + 1}`,
          method: 'post',
          data: lazyParams,
        }).then((response) => {
          console.log('requests resp:', response.data);
          context.commit('updateSummarizedLeasingRequests', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('resetsummarizedLeasingRequests');
          reject(error.response.data);
        });
      });
    },
    retrieveTradeNames(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/customer/customer-trade-names/',
          method: 'get',
        }).then((response) => {
          context.commit('updateTradeNames', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateTradeNames', []);
          reject(error.response.data);
        });
      });
    },
    retrieveRequestHistory(context, requestId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/request-history/${requestId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateRequestHistory', response.data);
          console.log(response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetRequestHistory', []);
          reject(error.response.data);
        });
      });
    },
    getRequiredDocumentsOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/options/document-type/',
          method: 'get',
        }).then((response) => {
          console.log('get the options');
          context.commit('updateRequiredDocumentsOptions', response.data);
          resolve(response);
        }).catch((error) => {
          console.log('get the options');
          context.commit('updateRequiredDocumentsOptions', []);
          reject(error.response.data);
        });
      });
    },
    getRequiredDocuments(context, requestId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/required-documents/by-request/${requestId}/`,
          method: 'get',
        }).then((response) => {
          console.log(requestId, response.data);
          context.commit('updateRequiredDocuments', response.data);

          resolve(response.data);
        }).catch((error) => {
          context.commit('updateRequiredDocuments', []);
          reject(error.response.data);
        });
      });
    },
    getDocument(context, id) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/required-documents/${id}/`,
          method: 'get',
        }).then((response) => {
          console.log(response.data);
          context.commit('updateDocument', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateDocument', []);
          reject(error.response.data);
        });
      });
    },
    saveRequiredDocument(context, { requiredDocument, files }) {
      return new Promise((resolve, reject) => {
        const formData = new FormData();

        // Append requiredDocument as data
        Object.entries(requiredDocument).forEach(([key, value]) => {
          formData.append(key, value);
        });
        console.log('store date', requiredDocument);
        console.log('store files', files);
        // Append files to the FormData object
        for (let i = 0; i < files.length; i += 1) {
          formData.append('files[]', files[i]);
        }

        axios({
          url: '/api/leasing/required-documents/',
          method: 'post',
          data: formData, // Use the FormData object as the data
          headers: {
            'Content-Type': 'multipart/form-data', // Set proper content type for files
          },
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCustomerRequests(context, searchInput) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/reports-search/?q=${searchInput}`,
          method: 'get',

        }).then((response) => {
          context.commit('updateCustomerRequests', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('resetCustomerRequests');
          reject(error.response.data);
        });
      });
    },

  },

  getters: {
    getLeasingRequest(state) {
      return state.leasingRequest;
    },
    getSummarizedRequests(state) {
      return state.summarizedLeasingRequests;
    },
    getTradeNames(state) {
      return state.tradeNames;
    },
    getRequestHistory(state) {
      return state.requestHistory;
    },
    RequiredDocumentsOptions(state) {
      return state.requiredDocumentsOptions;
    },
    RequiredDocuments(state) {
      return state.requiredDocuments;
    },
    getDocument(state) {
      return state.document;
    },
    customerRequests(state) {
      return state.customerRequests;
    },

  },
};
