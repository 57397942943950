import { createI18n } from 'vue-i18n';
import enUS from './en-US';
import arEG from './ar-EG';

const messages = {
  enUS,
  arEG,
};

const i18n = createI18n({
  locale: 'enUS', // Set default locale
  fallbackLocale: 'arEG', // Fallback locale if translation not found
  messages, // Set locale messages
});

export default i18n;
