import axios from 'axios';

export default {
  namespaced: true,
  state: {
    incomeStatement: [],
    assets: [],
    liabEquity: [],
    cashFlow: [],
    factSheet: [],
    summarizedOrrOptions: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    orr: [],
  },
  mutations: {
    updateIncomeStatement(state, newData) {
      console.log('new data', newData);
      state.incomeStatement = newData;
    },
    updateAssets(state, newData) {
      state.assets = newData;
    },
    updateLiabEquity(state, newData) {
      state.liabEquity = newData;
    },
    updateCashFlow(state, newData) {
      state.cashFlow = newData;
    },
    updateFactSheet(state, newData) {
      state.factSheet = newData;
    },
    updatesummarizedOrrOptions(state, newData) {
      state.summarizedOrrOptions = newData;
    },
    updateOrr(state, newData) {
      state.orr = newData;
    },
  },
  actions: {
    getIncomeStatement(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/income-statement-periods/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateIncomeStatement', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateIncomeStatement', []);
          reject(error.response.data);
        });
      });
    },
    getAssets(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/asset-periods/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateAssets', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateAssets', []);
          reject(error.response.data);
        });
      });
    },
    getLiabEquity(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/liab-equity-periods/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateLiabEquity', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateLiabEquity', []);
          reject(error.response.data);
        });
      });
    },
    //  Recode the get cash flow
    getCashFlow(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/cash-flow/${investigationId}/?export=False`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCashFlow', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateCashFlow', []);
          reject(error.response.data);
        });
      });
    },
    exportCashFlow(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/cash-flow/${investigationId}/?export=yes`,
          method: 'get',
          responseType: 'blob',
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error.response.data);
        });
      });
    },
    //  Recode the get Fact Sheet
    getFactSheet(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/fact-sheet/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateFactSheet', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateFactSheet', []);
          reject(error.response.data);
        });
      });
    },
    exportFactSheet(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/fact-sheet/${investigationId}/?export=yes`,
          method: 'get',
          responseType: 'blob',
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error.response.data);
        });
      });
    },
    saveIncomeStatements(context, incomeStatementInfo) {
      console.log(incomeStatementInfo);
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/credit/income-statement-periods/',
          method: 'post',
          data: incomeStatementInfo,
        })
          .then((response) => {
            context.commit('updateIncomeStatement', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveAssets(context, assetsInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/credit/asset-periods/',
          method: 'post',
          data: assetsInfo,
        })
          .then((response) => {
            context.commit('updateAssets', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveLiabEquity(context, liabEquityInfo) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/credit/liab-equity-periods/',
          method: 'post',
          data: liabEquityInfo,
        })
          .then((response) => {
            context.commit('updateLiabEquity', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSummarizedOrrOptions(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/risk/orr-sections/',
          method: 'get',
        }).then((response) => {
          context.commit('updatesummarizedOrrOptions', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updatesummarizedOrrOptions', []);
          reject(error.response.data);
        });
      });
    },
    getOrr(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/orr-report/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          console.log(response.data);
          context.commit('updateOrr', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateOrr', []);
          reject(error.response.data);
        });
      });
    },
    saveOrr(context, orrInfo) {
      const investigationId = orrInfo.id;
      console.log('investigationId', investigationId);
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/orr-report/${investigationId}/`,
          method: 'post',
          data: orrInfo,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // export Income statement historical excel
    exportIncomeStatementHistorical(context, investigationid) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/credit/historical-export/${investigationid}/?export=yes`,
          method: 'get',
          responseType: 'blob',
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error.response.data);
        });
      });
    },
    // export Investigation excel
    exportInvestigation(context, investigationid) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/leasing/investigations/${investigationid}/?export=yes`,
          method: 'get',
          responseType: 'blob',
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    incomeStatement(state) {
      return state.incomeStatement;
    },
    assets(state) {
      return state.assets;
    },
    liabEquity(state) {
      return state.liabEquity;
    },
    cashFlow(state) {
      return state.cashFlow;
    },
    factSheet(state) {
      return state.factSheet;
    },
    summarizedOrrOptions(state) {
      return state.summarizedOrrOptions;
    },
    orr(state) {
      return state.orr;
    },
  },
};
