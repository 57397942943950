import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import './layout/animation.css';

import { createApp, watch } from 'vue';
import axios from 'axios';

import moment from 'moment';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Chart from 'primevue/chart';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice';
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Dropdown from 'primevue/dropdown';
import DynamicDialog from 'primevue/dynamicdialog';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import VirtualScroller from 'primevue/virtualscroller';
import html2pdf from 'html2pdf.js';

import CodeHighlight from '@/components/CodeHighlight.vue';
import BlockViewer from '@/components/BlockViewer.vue';
import store from './store';
import router from './router';
import i18n from './i18n/index';
import App from './layout/AppLayout.vue';

// import '@/assets/styles.scss';
import './assets/styles.scss';

const app = createApp(App);
app.use(i18n);
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

// redirect to login page whenever token is no longer valid

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 403 || error.response.status === 401) {
      console.log('validated');
      // eslint-disable-next-line no-underscore-dangle
      const location = window.location.pathname;
      if (!location.includes('/auth/password/reset/confirm/') && !location.includes('/auth/login')) {
        console.log('user not authorized...');
        store.dispatch('users/setUnauthenticated');
        router.push('/auth/login/');
      }
    }
    return Promise.reject(error);
  },
);

app.use(router);
app.use(store);
// app.use(PrimeVue, { ripple: true });
const primevueConfig = {
  locale: {
    startsWith: i18n.global.t('primevueConfig.startsWith'),
    contains: i18n.global.t('primevueConfig.contains'),
    notContains: i18n.global.t('primevueConfig.notContains'),
    endsWith: i18n.global.t('primevueConfig.endsWith'),
    equals: i18n.global.t('primevueConfig.equals'),
    notEquals: i18n.global.t('primevueConfig.notEquals'),
    noFilter: i18n.global.t('primevueConfig.noFilter'),
    lt: i18n.global.t('primevueConfig.lt'),
    lte: i18n.global.t('primevueConfig.lte'),
    gt: i18n.global.t('primevueConfig.gt'),
    gte: i18n.global.t('primevueConfig.gte'),
    dateIs: i18n.global.t('primevueConfig.dateIs'),
    dateIsNot: i18n.global.t('primevueConfig.dateIsNot'),
    dateBefore: i18n.global.t('primevueConfig.dateBefore'),
    dateAfter: i18n.global.t('primevueConfig.dateAfter'),
    clear: i18n.global.t('primevueConfig.clear'),
    apply: i18n.global.t('primevueConfig.apply'),
    matchAll: i18n.global.t('primevueConfig.matchAll'),
    matchAny: i18n.global.t('primevueConfig.matchAny'),
    addRule: i18n.global.t('primevueConfig.addRule'),
    removeRule: i18n.global.t('primevueConfig.removeRule'),
    accept: i18n.global.t('primevueConfig.accept'),
    reject: i18n.global.t('primevueConfig.reject'),
    choose: i18n.global.t('primevueConfig.choose'),
    upload: i18n.global.t('primevueConfig.upload'),
    cancel: i18n.global.t('primevueConfig.cancel'),
    completed: i18n.global.t('primevueConfig.completed'),
    pending: i18n.global.t('primevueConfig.pending'),
    fileSizeTypes: i18n.global.t('primevueConfig.fileSizeTypes').split('_'),
    dayNames: i18n.global.t('primevueConfig.dayNames').split('_'),
    dayNamesShort: i18n.global.t('primevueConfig.dayNamesShort').split('_'),
    dayNamesMin: i18n.global.t('primevueConfig.dayNamesMin').split('_'),
    monthNames: i18n.global.t('primevueConfig.monthNames').split('_'),
    monthNamesShort: i18n.global.t('primevueConfig.monthNamesShort').split('_'),
    chooseYear: i18n.global.t('primevueConfig.chooseYear'),
    chooseMonth: i18n.global.t('primevueConfig.chooseMonth'),
    chooseDate: i18n.global.t('primevueConfig.chooseDate'),
    prevDecade: i18n.global.t('primevueConfig.prevDecade'),
    nextDecade: i18n.global.t('primevueConfig.nextDecade'),
    prevYear: i18n.global.t('primevueConfig.prevYear'),
    nextYear: i18n.global.t('primevueConfig.nextYear'),
    prevMonth: i18n.global.t('primevueConfig.prevMonth'),
    nextMonth: i18n.global.t('primevueConfig.nextMonth'),
    prevHour: i18n.global.t('primevueConfig.prevHour'),
    nextHour: i18n.global.t('primevueConfig.nextHour'),
    prevMinute: i18n.global.t('primevueConfig.prevMinute'),
    nextMinute: i18n.global.t('primevueConfig.nextMinute'),
    prevSecond: i18n.global.t('primevueConfig.prevSecond'),
    nextSecond: i18n.global.t('primevueConfig.nextSecond'),
    am: i18n.global.t('primevueConfig.am'),
    pm: i18n.global.t('primevueConfig.pm'),
    today: i18n.global.t('primevueConfig.today'),
    weekHeader: i18n.global.t('primevueConfig.weekHeader'),
    firstDayOfWeek: i18n.global.t('primevueConfig.firstDayOfWeek'),
    showMonthAfterYear: i18n.global.t('primevueConfig.showMonthAfterYear'),
    dateFormat: i18n.global.t('primevueConfig.dateFormat'),
    weak: i18n.global.t('primevueConfig.weak'),
    medium: i18n.global.t('primevueConfig.medium'),
    strong: i18n.global.t('primevueConfig.strong'),
    passwordPrompt: i18n.global.t('primevueConfig.passwordPrompt'),
    searchMessage: i18n.global.t('primevueConfig.searchMessage'),
    selectionMessage: i18n.global.t('primevueConfig.selectionMessage'),
    emptySelectionMessage: i18n.global.t('primevueConfig.emptySelectionMessage'),
    emptySearchMessage: i18n.global.t('primevueConfig.emptySearchMessage'),
    fileChosenMessage: i18n.global.t('primevueConfig.fileChosenMessage'),
    noFileChosenMessage: i18n.global.t('primevueConfig.noFileChosenMessage'),
    emptyMessage: i18n.global.t('primevueConfig.emptyMessage'),
    aria: {
      trueLabel: i18n.global.t('primevueConfig.aria.trueLabel'),
      falseLabel: i18n.global.t('primevueConfig.aria.falseLabel'),
      nullLabel: i18n.global.t('primevueConfig.aria.nullLabel'),
      star: i18n.global.t('primevueConfig.aria.star'),
      stars: i18n.global.t('primevueConfig.aria.stars'),
      selectAll: i18n.global.t('primevueConfig.aria.selectAll'),
      unselectAll: i18n.global.t('primevueConfig.aria.unselectAll'),
      close: i18n.global.t('primevueConfig.aria.close'),
      previous: i18n.global.t('primevueConfig.aria.previous'),
      next: i18n.global.t('primevueConfig.aria.next'),
      navigation: i18n.global.t('primevueConfig.aria.navigation'),
      scrollTop: i18n.global.t('primevueConfig.aria.scrollTop'),
      moveTop: i18n.global.t('primevueConfig.aria.moveTop'),
      moveUp: i18n.global.t('primevueConfig.aria.moveUp'),
      moveDown: i18n.global.t('primevueConfig.aria.moveDown'),
      moveBottom: i18n.global.t('primevueConfig.aria.moveBottom'),
      moveToTarget: i18n.global.t('primevueConfig.aria.moveToTarget'),
      moveToSource: i18n.global.t('primevueConfig.aria.moveToSource'),
      moveAllToTarget: i18n.global.t('primevueConfig.aria.moveAllToTarget'),
      moveAllToSource: i18n.global.t('primevueConfig.aria.moveAllToSource'),
      pageLabel: i18n.global.t('primevueConfig.aria.pageLabel'),
      firstPageLabel: i18n.global.t('primevueConfig.aria.firstPageLabel'),
      lastPageLabel: i18n.global.t('primevueConfig.aria.lastPageLabel'),
      nextPageLabel: i18n.global.t('primevueConfig.aria.nextPageLabel'),
      prevPageLabel: i18n.global.t('primevueConfig.aria.prevPageLabel'),
      rowsPerPageLabel: i18n.global.t('primevueConfig.aria.rowsPerPageLabel'),
      jumpToPageDropdownLabel: i18n.global.t('primevueConfig.aria.jumpToPageDropdownLabel'),
      jumpToPageInputLabel: i18n.global.t('primevueConfig.aria.jumpToPageInputLabel'),
      selectRow: i18n.global.t('primevueConfig.aria.selectRow'),
      unselectRow: i18n.global.t('primevueConfig.aria.unselectRow'),
      expandRow: i18n.global.t('primevueConfig.aria.expandRow'),
      collapseRow: i18n.global.t('primevueConfig.aria.collapseRow'),
      showFilterMenu: i18n.global.t('primevueConfig.aria.showFilterMenu'),
      hideFilterMenu: i18n.global.t('primevueConfig.aria.hideFilterMenu'),
      filterOperator: i18n.global.t('primevueConfig.aria.filterOperator'),
      filterConstraint: i18n.global.t('primevueConfig.aria.filterConstraint'),
      editRow: i18n.global.t('primevueConfig.aria.editRow'),
      saveEdit: i18n.global.t('primevueConfig.aria.saveEdit'),
      cancelEdit: i18n.global.t('primevueConfig.aria.cancelEdit'),
      listView: i18n.global.t('primevueConfig.aria.listView'),
      gridView: i18n.global.t('primevueConfig.aria.gridView'),
      slide: i18n.global.t('primevueConfig.aria.slide'),
      slideNumber: i18n.global.t('primevueConfig.aria.slideNumber'),
      zoomImage: i18n.global.t('primevueConfig.aria.zoomImage'),
      zoomIn: i18n.global.t('primevueConfig.aria.zoomIn'),
      zoomOut: i18n.global.t('primevueConfig.aria.zoomOut'),
      rotateRight: i18n.global.t('primevueConfig.aria.rotateRight'),
      rotateLeft: i18n.global.t('primevueConfig.aria.rotateLeft'),
    },
  },
  ripple: true,
};
app.use(PrimeVue, primevueConfig);

watch(
  () => i18n.global.locale, // Watch the current locale
  () => {
    primevueConfig.locale = {
      startsWith: i18n.global.t('primevueConfig.startsWith'),
      contains: i18n.global.t('primevueConfig.contains'),
      notContains: i18n.global.t('primevueConfig.notContains'),
      endsWith: i18n.global.t('primevueConfig.endsWith'),
      equals: i18n.global.t('primevueConfig.equals'),
      notEquals: i18n.global.t('primevueConfig.notEquals'),
      noFilter: i18n.global.t('primevueConfig.noFilter'),
      lt: i18n.global.t('primevueConfig.lt'),
      lte: i18n.global.t('primevueConfig.lte'),
      gt: i18n.global.t('primevueConfig.gt'),
      gte: i18n.global.t('primevueConfig.gte'),
      dateIs: i18n.global.t('primevueConfig.dateIs'),
      dateIsNot: i18n.global.t('primevueConfig.dateIsNot'),
      dateBefore: i18n.global.t('primevueConfig.dateBefore'),
      dateAfter: i18n.global.t('primevueConfig.dateAfter'),
      clear: i18n.global.t('primevueConfig.clear'),
      apply: i18n.global.t('primevueConfig.apply'),
      matchAll: i18n.global.t('primevueConfig.matchAll'),
      matchAny: i18n.global.t('primevueConfig.matchAny'),
      addRule: i18n.global.t('primevueConfig.addRule'),
      removeRule: i18n.global.t('primevueConfig.removeRule'),
      accept: i18n.global.t('primevueConfig.accept'),
      reject: i18n.global.t('primevueConfig.reject'),
      choose: i18n.global.t('primevueConfig.choose'),
      upload: i18n.global.t('primevueConfig.upload'),
      cancel: i18n.global.t('primevueConfig.cancel'),
      completed: i18n.global.t('primevueConfig.completed'),
      pending: i18n.global.t('primevueConfig.pending'),
      fileSizeTypes: i18n.global.t('primevueConfig.fileSizeTypes').split('_'),
      dayNames: i18n.global.t('primevueConfig.dayNames').split('_'),
      dayNamesShort: i18n.global.t('primevueConfig.dayNamesShort').split('_'),
      dayNamesMin: i18n.global.t('primevueConfig.dayNamesMin').split('_'),
      monthNames: i18n.global.t('primevueConfig.monthNames').split('_'),
      monthNamesShort: i18n.global.t('primevueConfig.monthNamesShort').split('_'),
      chooseYear: i18n.global.t('primevueConfig.chooseYear'),
      chooseMonth: i18n.global.t('primevueConfig.chooseMonth'),
      chooseDate: i18n.global.t('primevueConfig.chooseDate'),
      prevDecade: i18n.global.t('primevueConfig.prevDecade'),
      nextDecade: i18n.global.t('primevueConfig.nextDecade'),
      prevYear: i18n.global.t('primevueConfig.prevYear'),
      nextYear: i18n.global.t('primevueConfig.nextYear'),
      prevMonth: i18n.global.t('primevueConfig.prevMonth'),
      nextMonth: i18n.global.t('primevueConfig.nextMonth'),
      prevHour: i18n.global.t('primevueConfig.prevHour'),
      nextHour: i18n.global.t('primevueConfig.nextHour'),
      prevMinute: i18n.global.t('primevueConfig.prevMinute'),
      nextMinute: i18n.global.t('primevueConfig.nextMinute'),
      prevSecond: i18n.global.t('primevueConfig.prevSecond'),
      nextSecond: i18n.global.t('primevueConfig.nextSecond'),
      am: i18n.global.t('primevueConfig.am'),
      pm: i18n.global.t('primevueConfig.pm'),
      today: i18n.global.t('primevueConfig.today'),
      weekHeader: i18n.global.t('primevueConfig.weekHeader'),
      firstDayOfWeek: i18n.global.t('primevueConfig.firstDayOfWeek'),
      showMonthAfterYear: i18n.global.t('primevueConfig.showMonthAfterYear'),
      dateFormat: i18n.global.t('primevueConfig.dateFormat'),
      weak: i18n.global.t('primevueConfig.weak'),
      medium: i18n.global.t('primevueConfig.medium'),
      strong: i18n.global.t('primevueConfig.strong'),
      passwordPrompt: i18n.global.t('primevueConfig.passwordPrompt'),
      searchMessage: i18n.global.t('primevueConfig.searchMessage'),
      selectionMessage: i18n.global.t('primevueConfig.selectionMessage'),
      emptySelectionMessage: i18n.global.t('primevueConfig.emptySelectionMessage'),
      emptySearchMessage: i18n.global.t('primevueConfig.emptySearchMessage'),
      fileChosenMessage: i18n.global.t('primevueConfig.fileChosenMessage'),
      noFileChosenMessage: i18n.global.t('primevueConfig.noFileChosenMessage'),
      emptyMessage: i18n.global.t('primevueConfig.emptyMessage'),
      aria: {
        trueLabel: i18n.global.t('primevueConfig.aria.trueLabel'),
        falseLabel: i18n.global.t('primevueConfig.aria.falseLabel'),
        nullLabel: i18n.global.t('primevueConfig.aria.nullLabel'),
        star: i18n.global.t('primevueConfig.aria.star'),
        stars: i18n.global.t('primevueConfig.aria.stars'),
        selectAll: i18n.global.t('primevueConfig.aria.selectAll'),
        unselectAll: i18n.global.t('primevueConfig.aria.unselectAll'),
        close: i18n.global.t('primevueConfig.aria.close'),
        previous: i18n.global.t('primevueConfig.aria.previous'),
        next: i18n.global.t('primevueConfig.aria.next'),
        navigation: i18n.global.t('primevueConfig.aria.navigation'),
        scrollTop: i18n.global.t('primevueConfig.aria.scrollTop'),
        moveTop: i18n.global.t('primevueConfig.aria.moveTop'),
        moveUp: i18n.global.t('primevueConfig.aria.moveUp'),
        moveDown: i18n.global.t('primevueConfig.aria.moveDown'),
        moveBottom: i18n.global.t('primevueConfig.aria.moveBottom'),
        moveToTarget: i18n.global.t('primevueConfig.aria.moveToTarget'),
        moveToSource: i18n.global.t('primevueConfig.aria.moveToSource'),
        moveAllToTarget: i18n.global.t('primevueConfig.aria.moveAllToTarget'),
        moveAllToSource: i18n.global.t('primevueConfig.aria.moveAllToSource'),
        pageLabel: i18n.global.t('primevueConfig.aria.pageLabel'),
        firstPageLabel: i18n.global.t('primevueConfig.aria.firstPageLabel'),
        lastPageLabel: i18n.global.t('primevueConfig.aria.lastPageLabel'),
        nextPageLabel: i18n.global.t('primevueConfig.aria.nextPageLabel'),
        prevPageLabel: i18n.global.t('primevueConfig.aria.prevPageLabel'),
        rowsPerPageLabel: i18n.global.t('primevueConfig.aria.rowsPerPageLabel'),
        jumpToPageDropdownLabel: i18n.global.t('primevueConfig.aria.jumpToPageDropdownLabel'),
        jumpToPageInputLabel: i18n.global.t('primevueConfig.aria.jumpToPageInputLabel'),
        selectRow: i18n.global.t('primevueConfig.aria.selectRow'),
        unselectRow: i18n.global.t('primevueConfig.aria.unselectRow'),
        expandRow: i18n.global.t('primevueConfig.aria.expandRow'),
        collapseRow: i18n.global.t('primevueConfig.aria.collapseRow'),
        showFilterMenu: i18n.global.t('primevueConfig.aria.showFilterMenu'),
        hideFilterMenu: i18n.global.t('primevueConfig.aria.hideFilterMenu'),
        filterOperator: i18n.global.t('primevueConfig.aria.filterOperator'),
        filterConstraint: i18n.global.t('primevueConfig.aria.filterConstraint'),
        editRow: i18n.global.t('primevueConfig.aria.editRow'),
        saveEdit: i18n.global.t('primevueConfig.aria.saveEdit'),
        cancelEdit: i18n.global.t('primevueConfig.aria.cancelEdit'),
        listView: i18n.global.t('primevueConfig.aria.listView'),
        gridView: i18n.global.t('primevueConfig.aria.gridView'),
        slide: i18n.global.t('primevueConfig.aria.slide'),
        slideNumber: i18n.global.t('primevueConfig.aria.slideNumber'),
        zoomImage: i18n.global.t('primevueConfig.aria.zoomImage'),
        zoomIn: i18n.global.t('primevueConfig.aria.zoomIn'),
        zoomOut: i18n.global.t('primevueConfig.aria.zoomOut'),
        rotateRight: i18n.global.t('primevueConfig.aria.rotateRight'),
        rotateLeft: i18n.global.t('primevueConfig.aria.rotateLeft'),
      },
    };
    // Force PrimeVue to update its locale
    app.config.globalProperties.$primevue.config.locale = primevueConfig.locale;
  },
);

app.use(moment);
app.use(Chart);
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);
app.use(html2pdf);

app.directive('tooltip', Tooltip);
app.directive('badge', BadgeDirective);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.component('CodeHighlight', CodeHighlight);
app.component('BlockViewer', BlockViewer);

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Chart', Chart);
app.component('Carousel', Carousel);
app.component('CascadeSelect', CascadeSelect);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('DeferredContent', DeferredContent);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dock', Dock);
app.component('Dropdown', Dropdown);
app.component('DynamicDialog', DynamicDialog);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Galleria', Galleria);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Row', Row);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Terminal', Terminal);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('VirtualScroller', VirtualScroller);

app.mount('#app');
