import axios from 'axios';

export default {
  namespaced: true,
  state: {
    customer: {
      request_date: null,
      branch: '',
      customer_name: '',
      customer_id_type: '',
      customer_id_number: '',
      customer_id_release_date: null,
      date_of_birth: null,
      country_of_birth: '',
      marital_status: '',
      number_of_children: 0,
      education_level: '',
      customer_governorate: '',
      customer_district: '',
      customer_street: '',
      customer_neighbor: '',
      customer_housing_ownership: '',
      customer_telephone: '',
      customer_mobile_number: '',
      customer_email_address: '',
      company_name: '',
      trade_name: '',
      company_legal_structure: '',
      sector: '',
      number_of_branches: 0,
      number_of_employees: 0,
      business_start_date: null,
      industry: '',
      sub_industry: '',
      commercial_register_number: '',
      last_renewal_date: null,
      license_number: '',
      license_renewal_date: null,
      other_activities: '',
      governorate: '',
      district: '',
      street: '',
      neighbor: '',
      housing_ownership: '',
      telephone: '',
      mobile_number: '',
      email_address: '',
      responsible_for_management: '',
      responsible_rm: '',
      contact_person: '',
      contact_person_email_address: '',
      contact_person_number: '',
      manager_relation: '',
      manager_mobile_number: '',
      company_structure: [],
      summary: '',
      request_status: '',
    },
    summarizedCustomers: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  },
  mutations: {
    updateCustomer(state, newCustomer) {
      state.customer = newCustomer;
    },
    resetCustomer(state) {
      state.customer = {
        request_date: null,
        branch: null,
        customer_name: '',
        customer_id_type: '',
        customer_id_number: '',
        customer_id_release_date: null,
        date_of_birth: null,
        country_of_birth: '',
        marital_status: '',
        number_of_children: 0,
        education_level: '',
        customer_governorate: '',
        customer_district: '',
        customer_street: '',
        customer_neighbor: '',
        customer_housing_ownership: '',
        customer_telephone: '',
        customer_mobile_number: '',
        customer_email_address: '',
        company_name: '',
        trade_name: '',
        company_legal_structure: '',
        sector: '',
        number_of_branches: 0,
        number_of_employees: 0,
        business_start_date: null,
        industry: '',
        commercial_register_number: '',
        last_renewal_date: null,
        license_number: '',
        license_renewal_date: null,
        other_activities: '',
        governorate: '',
        district: '',
        street: '',
        neighbor: '',
        housing_ownership: '',
        telephone: '',
        mobile_number: '',
        email_address: '',
        responsible_for_management: '',
        responsible_rm: '',
        contact_person: '',
        contact_person_email_address: '',
        contact_person_number: '',
        manager_relation: '',
        manager_mobile_number: '',
        company_structure: [],
        summary: '',
        request_status: '',
      };
    },
    updateSummarizedCustomers(state, newCustomers) {
      state.summarizedCustomers = newCustomers;
    },
  },
  actions: {
    saveCustomer(context, formData) {
      const id = formData.get('id');
      console.log('store here', id === '0');
      const reqMethod = id === '0' ? 'post' : 'put';
      const reqUrl = id === '0'
        ? '/api/customer/create/'
        : `/api/customer/edit/${id}/`;
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: reqMethod,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCustomer(context, customerId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/customer/customers/${customerId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCustomer', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetCustomer');
          reject(error.response.data);
        });
      });
    },
    getSummarizedCustomers(context, lazyParams) {
      console.log('page number:', lazyParams);
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/customer/customers/?page=${lazyParams.page + 1}`,
          method: 'post',
          data: lazyParams,
        }).then((response) => {
          context.commit('updateSummarizedCustomers', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateSummarizedCustomers', []);
          reject(error.response.data);
        });
      });
    },
    async mergeFiles(context, formData) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/customer/merge/',
          method: 'post',
          responseType: 'blob',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    getCustomer(state) {
      return state.customer;
    },
    getSummarizedCustomers(state) {
      return state.summarizedCustomers;
    },
  },
};
