import axios from 'axios';

export default {
  namespaced: true,
  state: {
    mainReport: [],
    detailedReport: [],
    industryConcentrationReport: [],
    creditMemoExport: {},
    creditRiskMemoExport: {},
  },
  mutations: {
    updateMainReport(state, newData) {
      state.mainReport = newData;
    },
    updateDetailedReport(state, newData) {
      state.detailedReport = newData;
    },
    updateIndustryConcentrationReport(state, newData) {
      state.industryConcentrationReport = newData;
    },
    updateCreditMemoExport(state, newData) {
      state.creditMemoExport = newData;
    },
    updateCreditRiskMemoExport(state, newData) {
      state.creditRiskMemoExport = newData;
    },
  },
  actions: {
    getMainReport(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/reports/main-report',
          method: 'get',
        }).then((response) => {
          context.commit('updateMainReport', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateMainReport', []);
          reject(error.response.data);
        });
      });
    },
    getDetailedReport(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/reports/detailed-report',
          method: 'get',
        }).then((response) => {
          context.commit('updateDetailedReport', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateDetailedReport', []);
          reject(error.response.data);
        });
      });
    },
    getIndustryConcentrationReport(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/reports/industry-concentration-report',
          method: 'get',
        }).then((response) => {
          context.commit('updateIndustryConcentrationReport', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateIndustryConcentrationReport', []);
          reject(error.response.data);
        });
      });
    },
    getCreditMemoExport(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/credit-memo-export/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditMemoExport', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateCreditMemoExport', {});
          reject(error.response.data);
        });
      });
    },
    getCreditRiskMemoExport(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/credit-risk-memo-export/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditRiskMemoExport', response.data);
          resolve(response);
        }).catch((error) => {
          context.commit('updateCreditRiskMemoExport', {});
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    mainReportData(state) {
      return state.mainReport;
    },
    detailedReportData(state) {
      return state.detailedReport;
    },
    industryConcentrationReportData(state) {
      return state.industryConcentrationReport;
    },
    creditMemoExportData(state) {
      return state.creditMemoExport;
    },
    creditRiskMemoExportData(state) {
      return state.creditRiskMemoExport;
    },
  },
};
