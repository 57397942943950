import axios from 'axios';

export default {
  namespaced: true,
  state: {
    investigationsCycleDetails: [],
    creditMemoReadOnly: {
      investigation_fields: {
        id: 0,
        company_name: '',
        trade_name: '',
        governorate: null,
        headquarter_ownership: null,
        company_legal_structure: null,
        sector: null,
        industry: null,
        sub_industry: null,
        real_activity: '',
        branch: null,
        business_start_date: null,
        authorized_capital: 0,
        capital_paid: 0,
        commercial_register_number: 0,
        license_renewal_date: null,
        license_number: null,
        current_employee_number: 0,
        request_date: null,
        number_of_banks: 0,
        company_ownership_structure: [],
        cbe_position: [],
        total_authorized_facilities: 0,
        total_used_facilities: 0,
      },
      address: '',
      expected_orr_rating: 0,
      current_orr_rating: '',
      facilities_names: [],
      facilities_amounts: {
        amounts: [],
        total_existing: 0,
      },
      security: 0,
      tenor: '',
      facility: '',
      application_number: '',
      memo_purpose: '',
      shareholders_total_percentage: 0,
      shareholders_total_amount: 0,
      iscore_names: [],
      key_customers_names: [],
      key_suppliers_names: [],
      cash_flow: [],
      sales_and_profitability: [],
      assets_efficiency: [],
      main_financial_indicator: [],
      capital_structure: [],
      risk: {},
    },
    creditMemo: {
      id: 0,
      credit_memo_special_terms: [],
      credit_memo_key_customers: [],
      credit_memo_key_suppliers: [],
      authority_of_approval: false,
      approval_date: null,
      type_of_license: '',
      fi_classification: '',
      outstanding: 0,
      purpose_clarification: '',
      pricing_term: '',
      pricing_percentage: 0,
      fees_term: '',
      fees_percentage: 0,
      draw_down_period: 0,
      down_payment: 0,
      residual_value: 0,
      source_of_repayment: '',
      collateral: '',
      deal_mechanism: '',
      profitability: '',
      company_profile_and_background: '',
      share_holders_comment: '',
      iscore_comment: '',
      reputation_in_market: false,
      situation_with_banks: false,
      bankruptcy_situation: false,
      tax_situation: false,
      insurance_situation: false,
      protest_situation: false,
      investigation_comment: '',
      credit_comment: '',
      operational_cycle: '',
      inventory_policy: '',
      industry_brief: '',
      sales_and_profitability_credit_comment: '',
      asset_efficiency_credit_comment: '',
      capital_structure_comment: '',
      projection_comment: '',
      officer_approval: false,
      officer_recommendations: '',
      supervisor_approval: false,
      supervisor_recommendations: '',
      head_approval: false,
      head_recommendations: '',
      main_comments: '',
      recommendations: '',
      created_at: null,
      updated_at: null,
      investigation: 0,
      sector: null,
      industry: null,
      sub_industry: null,
      created_by: null,
      last_modified_by: null,
    },
    creditRiskMemo: {
      credit_risk_memo_special_terms: [],
      credit_risk_memo_credit_committee: [],
      credit_risk_memo_executive_committee: [],
      credit_risk_memo_risk_team: [],
      status: '',
      authority_of_approval: false,
      approval_date: null,
      source_of_repayment: '',
      collateral: '',
      fees_term: '',
      fees_percentage: 0.0,
      draw_down_period: null,
      company_profile_comment: '',
      operational_cycle: '',
      industry_analysis: '',
      industry_analysis_comment: '',
      main_financial_indicator_comment: '',
      cash_flow_comment: '',
      investigation_summary_positive_points: '',
      investigation_summary_negative_points: '',
      projection_comment: '',
      portfolio_analysis_comment: '',
      conclusion_and_comments: '',
      investigation: null,
    },
  },
  mutations: {
    updateInvestigationsCycleDetails(state, newInvestigationsCycleDetails) {
      state.investigationsCycleDetails = newInvestigationsCycleDetails;
    },
    updateCreditMemoReadOnly(state, newCreditMemoReadOnly) {
      state.creditMemoReadOnly = newCreditMemoReadOnly;
    },
    updateCreditMemo(state, newCreditMemo) {
      state.creditMemo = newCreditMemo;
    },
    updateCreditRiskMemo(state, newCreditRiskMemo) {
      state.creditRiskMemo = newCreditRiskMemo;
    },
    resetCreditMemoReadOnly(state) {
      state.creditMemoReadOnly = {
        investigation_fields: {
          id: 0,
          company_name: '',
          trade_name: '',
          governorate: null,
          headquarter_ownership: null,
          company_legal_structure: null,
          sector: null,
          industry: null,
          sub_industry: null,
          real_activity: '',
          branch: null,
          business_start_date: null,
          authorized_capital: 0,
          capital_paid: 0,
          commercial_register_number: 0,
          license_renewal_date: null,
          license_number: null,
          current_employee_number: 0,
          request_date: null,
          number_of_banks: 0,
          company_ownership_structure: [],
          cbe_position: [],
          total_authorized_facilities: 0,
          total_used_facilities: 0,
        },
        address: '',
        expected_orr_rating: 0,
        current_orr_rating: '',
        facilities_names: [],
        facilities_amounts: {
          amounts: [],
          total_existing: 0,
        },
        security: 0,
        tenor: '',
        facility: '',
        application_number: '',
        memo_purpose: '',
        shareholders_total_percentage: 0,
        shareholders_total_amount: 0,
        iscore_names: [],
        key_customers_names: [],
        key_suppliers_names: [],
        cash_flow: [],
        sales_and_profitability: [],
        assets_efficiency: [],
        main_financial_indicator: [],
        capital_structure: [],
        risk: {},
      };
    },
    resetCreditMemo(state) {
      state.creditMemo = {
        id: 0,
        credit_memo_special_terms: [],
        credit_memo_key_customers: [],
        credit_memo_key_suppliers: [],
        authority_of_approval: false,
        approval_date: null,
        type_of_license: '',
        fi_classification: '',
        outstanding: 0,
        purpose_clarification: '',
        pricing_term: '',
        pricing_percentage: 0,
        fees_term: '',
        fees_percentage: 0,
        draw_down_period: 0,
        down_payment: 0,
        residual_value: 0,
        source_of_repayment: '',
        collateral: '',
        deal_mechanism: '',
        profitability: '',
        company_profile_and_background: '',
        share_holders_comment: '',
        iscore_comment: '',
        reputation_in_market: false,
        situation_with_banks: false,
        bankruptcy_situation: false,
        tax_situation: false,
        insurance_situation: false,
        protest_situation: false,
        investigation_comment: '',
        credit_comment: '',
        operational_cycle: '',
        inventory_policy: '',
        industry_brief: '',
        sales_and_profitability_credit_comment: '',
        asset_efficiency_credit_comment: '',
        capital_structure_comment: '',
        projection_comment: '',
        officer_approval: false,
        officer_recommendations: '',
        supervisor_approval: false,
        supervisor_recommendations: '',
        head_approval: false,
        head_recommendations: '',
        main_comments: '',
        recommendations: '',
        created_at: null,
        updated_at: null,
        investigation: null,
        sector: null,
        industry: null,
        sub_industry: null,
        created_by: null,
        last_modified_by: null,
      };
    },
  },
  actions: {
    getInvestigationsCycleDetails(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/risk/investigations-cycle-details/',
          method: 'get',
        }).then((response) => {
          context.commit('updateInvestigationsCycleDetails', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateInvestigationsCycleDetails');
          reject(error.response.data);
        });
      });
    },
    saveCreditMemo(context, newData) {
      const investigationId = newData.investigation;
      const reqMethod = newData.urlMethod === 'new' ? 'post' : 'put';
      const reqUrl = `/api/risk/credit-memo/${investigationId}/`;
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: reqMethod,
          data: newData,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    saveCreditRiskMemo(context, newData) {
      const investigationId = newData.investigation;
      const reqMethod = newData.urlMethod === 'new' ? 'post' : 'put';
      const reqUrl = `/api/risk/credit-risk-memo/${investigationId}/`;
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: reqMethod,
          data: newData,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCreditMemoReadOnly(context, investigationId, urlMethod) {
      return new Promise((resolve, reject) => {
        console.log(urlMethod);
        const reqMethod = 'get';
        axios({
          url: `/api/risk/credit-memo-read-only/${investigationId}/`,
          method: reqMethod,
        }).then((response) => {
          context.commit('updateCreditMemoReadOnly', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetCreditMemoReadOnly');
          reject(error.response.data);
        });
      });
    },
    getCreditMemo(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/credit-memo/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditMemo', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetCreditMemo');
          reject(error.response.data);
        });
      });
    },
    getRiskCreditMemo(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/credit-risk-memo/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateCreditRiskMemo', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetCreditMemo');
          reject(error.response.data);
        });
      });
    },
    exportCreditMemo(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/credit-memo-export/${investigationId}/?export=yes`,
          method: 'get',
          responseType: 'blob',
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error.response.data);
        });
      });
    },
    exportCreditRiskMemo(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/risk/credit-risk-memo-export/${investigationId}/?export=yes`,
          method: 'get',
          responseType: 'blob',
        }).then((response) => {
          resolve(response);
        }).catch((error) => {
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    getInvestigationsCycleDetails(state) {
      return state.investigationsCycleDetails;
    },
    getCreditMemoReadOnly(state) {
      return state.creditMemoReadOnly;
    },
    getCreditMemo(state) {
      return state.creditMemo;
    },
    getCreditRiskMemo(state) {
      return state.creditRiskMemo;
    },
  },
};
