import axios from 'axios';

export default {
  namespaced: true,
  state: {
    years: [],
    repaymentSchedules: {
      id: 0,
      disbursement_date: null,
      first_rent_due: null,
      rent_period: 0,
      assets_cost: 0,
      down_payment: 0,
      percent_of_finance: 0,
      net_financed_amount: 0,
      percent_of_residual_value: 0,
      residual_value: 0,
      bench_mark: 0,
      spread: 0,
      declining_interest_rate: 0,
      financing_term: 0,
      term_in_rent_period: 0,
      lease_rent_type: 0,
      rent: 0,
      total_rents: 0,
      gross_financial_income: 0,
      flat_interest_rate: 0,
      years_principle_sum: {},
      created_at: null,
      updated_at: null,
      investigation: 0,
      created_by: 0,
      last_modified_by: 0,
    },
    loanRunOff: {
      id: 0,
      period: null,
      scenario: '',
      short_new_amount: 0,
      current_outstanding_stl: 0,
      current_portion_of_current_lease: 0,
      current_portion_of_new_lease: 0,
      current_portion_of_current_mtl: 0,
      current_portion_of_new_mtl: 0,
      short_total: 0,
      short_rate: 0,
      lease_new_amount: 0,
      lease_total_repayments: 0,
      lease_new_facilities_outstanding: 0,
      lease_current_facilities_outstanding: 0,
      lease_total: 0,
      lease_rate: 0,
      long_new_amount: 0,
      long_total_repayments: 0,
      long_new_facilities_outstanding: 0,
      long_current_facilities_outstanding: 0,
      long_total: 0,
      long_rate: 0,
      gross_interest_expense_exist: 0,
      created_at: null,
      updated_at: null,
      investigation: 0,
      created_by: 0,
      last_modified_by: 0,
    },
    fixedAssetProjection: {
      id: 0,
      scenario: '',
      period: null,
      manufacturing_land: 0,
      manufacturing_land_depreciation_percentage: 0,
      manufacturing_land_depreciation: 0,
      manufacturing_building_and_improvement: 0,
      manufacturing_building_and_improvement_depreciation_percentage: 0,
      manufacturing_building_and_improvement_depreciation: 0,
      manufacturing_equipment: 0,
      manufacturing_equipment_depreciation_percentage: 0,
      manufacturing_equipment_depreciation: 0,
      manufacturing_construction_in_progress: 0,
      manufacturing_construction_in_progress_depreciation_percentage: 0,
      manufacturing_construction_in_progress_depreciation: 0,
      manufacturing_other: 0,
      manufacturing_other_depreciation_percentage: 0,
      manufacturing_other_depreciation: 0,
      manufacturing_gross_planet: 0,
      manufacturing_total_depreciation: 0,
      admin_land: 0,
      admin_land_depreciation_percentage: 0,
      admin_land_depreciation: 0,
      admin_building_and_improvement: 0,
      admin_building_and_improvement_depreciation_percentage: 0,
      admin_building_and_improvement_depreciation: 0,
      admin_equipment: 0,
      admin_equipment_depreciation_percentage: 0,
      admin_equipment_depreciation: 0,
      admin_construction_in_progress: 0,
      admin_construction_in_progress_depreciation_percentage: 0,
      admin_construction_in_progress_depreciation: 0,
      admin_other: 0,
      admin_other_depreciation_percentage: 0,
      admin_other_depreciation: 0,
      admin_gross_planet: 0,
      admin_total_depreciation: 0,
      land_sold_out: 0,
      building_and_improvement_sold_out: 0,
      equipment_sold_out: 0,
      construction_in_progress_sold_out: 0,
      other_sold_out: 0,
      created_at: null,
      updated_at: null,
      investigation: 0,
      created_by: 0,
      last_modified_by: 0,
    },
    projectionAssumptions: [],
  },
  mutations: {
    updateProjectionYears(state, newProjectionYears) {
      state.years = newProjectionYears;
    },
    resetProjectionYears(state) {
      state.years = [];
    },
    updateLoanRunOff(state, newLoanRunOff) {
      state.loanRunOff = newLoanRunOff;
    },
    updateProjectionAssumptions(state, newProjectionAssumptions) {
      state.projectionAssumptions = newProjectionAssumptions;
    },
    resetLoanRunOff(state) {
      state.loanRunOff = {
        id: 0,
        period: null,
        scenario: '',
        short_new_amount: 0,
        current_outstanding_stl: 0,
        current_portion_of_current_lease: 0,
        current_portion_of_new_lease: 0,
        current_portion_of_current_mtl: 0,
        current_portion_of_new_mtl: 0,
        short_total: 0,
        short_rate: 0,
        lease_new_amount: 0,
        lease_total_repayments: 0,
        lease_new_facilities_outstanding: 0,
        lease_current_facilities_outstanding: 0,
        lease_total: 0,
        lease_rate: 0,
        long_new_amount: 0,
        long_total_repayments: 0,
        long_new_facilities_outstanding: 0,
        long_current_facilities_outstanding: 0,
        long_total: 0,
        long_rate: 0,
        gross_interest_expense_exist: 0,
        created_at: null,
        updated_at: null,
        investigation: 0,
        created_by: 0,
        last_modified_by: 0,
      };
    },
    updateRepaymentSchedules(state, newRepaymentSchedules) {
      state.repaymentSchedules = newRepaymentSchedules;
    },
    resetRepaymentSchedules(state) {
      state.repaymentSchedules = {
        id: 0,
        disbursement_date: null,
        first_rent_due: null,
        rent_period: 0,
        assets_cost: 0,
        down_payment: 0,
        percent_of_finance: 0,
        net_financed_amount: 0,
        percent_of_residual_value: 0,
        residual_value: 0,
        bench_mark: 0,
        spread: 0,
        declining_interest_rate: 0,
        financing_term: 0,
        term_in_rent_period: 0,
        lease_rent_type: 0,
        rent: 0,
        total_rents: 0,
        gross_financial_income: 0,
        flat_interest_rate: 0,
        years_principle_sum: {},
        created_at: null,
        updated_at: null,
        investigation: 0,
        created_by: 0,
        last_modified_by: 0,
      };
    },
    updateFixedAssetProjection(state, newFixedAssetProjection) {
      state.fixedAssetProjection = newFixedAssetProjection;
    },
    resetFixedAssetProjection(state) {
      state.fixedAssetProjection = {
        id: 0,
        scenario: '',
        period: null,
        manufacturing_land: 0,
        manufacturing_land_depreciation_percentage: 0,
        manufacturing_land_depreciation: 0,
        manufacturing_building_and_improvement: 0,
        manufacturing_building_and_improvement_depreciation_percentage: 0,
        manufacturing_building_and_improvement_depreciation: 0,
        manufacturing_equipment: 0,
        manufacturing_equipment_depreciation_percentage: 0,
        manufacturing_equipment_depreciation: 0,
        manufacturing_construction_in_progress: 0,
        manufacturing_construction_in_progress_depreciation_percentage: 0,
        manufacturing_construction_in_progress_depreciation: 0,
        manufacturing_other: 0,
        manufacturing_other_depreciation_percentage: 0,
        manufacturing_other_depreciation: 0,
        manufacturing_gross_planet: 0,
        manufacturing_total_depreciation: 0,
        admin_land: 0,
        admin_land_depreciation_percentage: 0,
        admin_land_depreciation: 0,
        admin_building_and_improvement: 0,
        admin_building_and_improvement_depreciation_percentage: 0,
        admin_building_and_improvement_depreciation: 0,
        admin_equipment: 0,
        admin_equipment_depreciation_percentage: 0,
        admin_equipment_depreciation: 0,
        admin_construction_in_progress: 0,
        admin_construction_in_progress_depreciation_percentage: 0,
        admin_construction_in_progress_depreciation: 0,
        admin_other: 0,
        admin_other_depreciation_percentage: 0,
        admin_other_depreciation: 0,
        admin_gross_planet: 0,
        admin_total_depreciation: 0,
        land_sold_out: 0,
        building_and_improvement_sold_out: 0,
        equipment_sold_out: 0,
        construction_in_progress_sold_out: 0,
        other_sold_out: 0,
        created_at: null,
        updated_at: null,
        investigation: 0,
        created_by: 0,
        last_modified_by: 0,
      };
    },
  },

  actions: {
    saveRepaymentSchedules(context, repaymentSchedulesInfo) {
      const reqUrl = '/api/projection/repayment-schedules/';
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: 'post',
          data: repaymentSchedulesInfo,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getRepaymentSchedules(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/projection/repayment-schedules/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          const result = response.data;
          result.percent_of_finance *= 100;
          result.percent_of_residual_value *= 100;
          result.bench_mark *= 100;
          result.spread *= 100;
          console.log('first_rent_due', result.first_rent_due);
          result.disbursement_date = result.disbursement_date
            ? new Date(result.disbursement_date) : null;
          result.first_rent_due = result.first_rent_due ? new Date(result.first_rent_due) : null;

          context.commit('updateRepaymentSchedules', result);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetRepaymentSchedules');
          reject(error.response.data);
        });
      });
    },
    saveLoanRunoff(context, runoffInfo) {
      const reqUrl = '/api/projection/loan-runoff/';
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: 'post',
          data: runoffInfo,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getLoanRunoff(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/projection/loan-runoff/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateLoanRunOff', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetLoanRunOff');
          reject(error.response.data);
        });
      });
    },
    saveFixedAssetProjection(context, fixedAssetProjectionInfo) {
      const reqUrl = '/api/projection/fixed-asset-projection/';
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: 'post',
          data: fixedAssetProjectionInfo,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getFixedAssetProjection(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/projection/fixed-asset-projection/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateFixedAssetProjection', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetFixedAssetProjection');
          reject(error.response.data);
        });
      });
    },
    getProjectionYears(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/projection/projection-years/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateProjectionYears', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('resetProjectionYears');
          reject(error.response.data);
        });
      });
    },
    getProjectionAssumptions(context, investigationId) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/api/projection/projection-assumptions/by-investigation/${investigationId}/`,
          method: 'get',
        }).then((response) => {
          context.commit('updateProjectionAssumptions', response.data);
          resolve(response.data);
        }).catch((error) => {
          context.commit('updateProjectionAssumptions', []);
          reject(error.response.data);
        });
      });
    },
    saveProjectionAssumptions(context, projectionAssumptions) {
      const reqUrl = '/api/projection/projection-assumptions/';
      return new Promise((resolve, reject) => {
        axios({
          url: reqUrl,
          method: 'post',
          data: projectionAssumptions,
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    repaymentSchedules(state) {
      return state.repaymentSchedules;
    },
    loanRunOff(state) {
      return state.loanRunOff;
    },
    fixedAssetProjection(state) {
      return state.fixedAssetProjection;
    },
    projectionYears(state) {
      return state.years;
    },
    projectionAssumptions(state) {
      return state.projectionAssumptions;
    },
  },
};
