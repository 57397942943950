import axios from 'axios';

export default {
  namespaced: true,
  state: {
    chartsData: {
      // request_facility_chart: {
      //   legends: [],
      //   data: [],
      // },
    },
  },
  mutations: {
    updateChartsData(state, newData) {
      state.chartsData = newData;
    },
  },
  actions: {
    getChartsData(context) {
      return new Promise((resolve, reject) => {
        axios({
          url: '/api/risk/charts-data/',
          method: 'get',
        }).then((response) => {
          context.commit('updateChartsData', response.data);
          console.log('fired');
          resolve(response);
        }).catch((error) => {
          context.commit('updateChartsData', []);
          reject(error.response.data);
        });
      });
    },
  },
  getters: {
    chartsData(state) {
      return state.chartsData;
    },
  },
};
